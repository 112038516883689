import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAlert, LazyCheckmarkList, LazyContactFormL4, LazyCoreAccordion, LazyCoreAccordionList, LazyCoreAdvancedTitle, LazyCoreBanner, LazyCoreCountdownSection, LazyCoreDynamicComponents, LazyCoreEventComponent, LazyCoreFeatureList, LazyCoreLayout, LazyCoreLinkWrapper, LazyCorePage, LazyCorePropelProductCard, LazyCoreSimpleCard, LazyCoreSimpleImage, LazyCoreSimpleLink, LazyCoreSimpleRichText, LazyCoreSimpleTitle, LazyCoreVideo, LazyCountdownCodeReveal, LazyCountdownEmailSubscription, LazyCountdownEmailSubscriptionForm, LazyCountdownTimer, LazyFormInput, LazyFormSelect, LazyFormTextarea, LazyL4Accordion, LazyL4AirportAccordion, LazyL4AirportParkingTabs, LazyL4AirportTabs, LazyL4Anchor, LazyL4ArticlesList, LazyL4Banner, LazyL4Benefits, LazyL4BlockUSP, LazyL4Breadcrumbs, LazyL4Button, LazyL4CarParkingOptions, LazyL4ComponentList, LazyL4Congratulations, LazyL4ContactCard, LazyL4Container, LazyL4CountryLink, LazyL4CustomerReviews, LazyL4EmailSignup, LazyL4EmailSignupNew, LazyL4EmbeddedPromotion, LazyL4Explainer, LazyL4Features, LazyL4FooterContainer, LazyL4HeaderContainer, LazyL4Hello, LazyL4HelpMultiFaqsSection, LazyL4HelpTabs, LazyL4HelpTile, LazyL4HelpTilesGrid, LazyL4Image, LazyL4IndexCountries, LazyL4LandingSection, LazyL4Links, LazyL4Map, LazyL4MulticolumnLayout, LazyL4NewsSection, LazyL4Page, LazyL4PageTitle, LazyL4Partners, LazyL4ProductContent, LazyL4ProductGrid, LazyL4ProductHeader, LazyL4ProductMap, LazyL4ProductPage, LazyL4ProductPricingTable, LazyL4QuoteForm, LazyL4QuoteFormExtended, LazyL4QuoteFormHotels, LazyL4ReviewsList, LazyL4SeoSection, LazyL4StandardContent, LazyL4StatsSection, LazyL4Testimonial, LazyL4TopLocations, LazyL4TopRatedCarParks, LazyL4TransferOptionsContainer, LazyL4TransfersEmailSignup, LazyL4TrustedPartners, LazyL4TwoColumn, LazyLoungeBookingCard, LazyOTCookieButton, LazyOTCookieTables, LazyOffersSection, LazyOtNotice, LazyTabbedSearchboxHero, LazyUsefulLinks, LazyNuxtIcon } from '#components'
const lazyGlobalComponents = [
  ["Alert", LazyAlert],
["CheckmarkList", LazyCheckmarkList],
["ContactFormL4", LazyContactFormL4],
["CoreAccordion", LazyCoreAccordion],
["CoreAccordionList", LazyCoreAccordionList],
["CoreAdvancedTitle", LazyCoreAdvancedTitle],
["CoreBanner", LazyCoreBanner],
["CoreCountdownSection", LazyCoreCountdownSection],
["CoreDynamicComponents", LazyCoreDynamicComponents],
["CoreEventComponent", LazyCoreEventComponent],
["CoreFeatureList", LazyCoreFeatureList],
["CoreLayout", LazyCoreLayout],
["CoreLinkWrapper", LazyCoreLinkWrapper],
["CorePage", LazyCorePage],
["CorePropelProductCard", LazyCorePropelProductCard],
["CoreSimpleCard", LazyCoreSimpleCard],
["CoreSimpleImage", LazyCoreSimpleImage],
["CoreSimpleLink", LazyCoreSimpleLink],
["CoreSimpleRichText", LazyCoreSimpleRichText],
["CoreSimpleTitle", LazyCoreSimpleTitle],
["CoreVideo", LazyCoreVideo],
["CountdownCodeReveal", LazyCountdownCodeReveal],
["CountdownEmailSubscription", LazyCountdownEmailSubscription],
["CountdownEmailSubscriptionForm", LazyCountdownEmailSubscriptionForm],
["CountdownTimer", LazyCountdownTimer],
["FormInput", LazyFormInput],
["FormSelect", LazyFormSelect],
["FormTextarea", LazyFormTextarea],
["L4Accordion", LazyL4Accordion],
["L4AirportAccordion", LazyL4AirportAccordion],
["L4AirportParkingTabs", LazyL4AirportParkingTabs],
["L4AirportTabs", LazyL4AirportTabs],
["L4Anchor", LazyL4Anchor],
["L4ArticlesList", LazyL4ArticlesList],
["L4Banner", LazyL4Banner],
["L4Benefits", LazyL4Benefits],
["L4BlockUSP", LazyL4BlockUSP],
["L4Breadcrumbs", LazyL4Breadcrumbs],
["L4Button", LazyL4Button],
["L4CarParkingOptions", LazyL4CarParkingOptions],
["L4ComponentList", LazyL4ComponentList],
["L4Congratulations", LazyL4Congratulations],
["L4ContactCard", LazyL4ContactCard],
["L4Container", LazyL4Container],
["L4CountryLink", LazyL4CountryLink],
["L4CustomerReviews", LazyL4CustomerReviews],
["L4EmailSignup", LazyL4EmailSignup],
["L4EmailSignupNew", LazyL4EmailSignupNew],
["L4EmbeddedPromotion", LazyL4EmbeddedPromotion],
["L4Explainer", LazyL4Explainer],
["L4Features", LazyL4Features],
["L4FooterContainer", LazyL4FooterContainer],
["L4HeaderContainer", LazyL4HeaderContainer],
["L4Hello", LazyL4Hello],
["L4HelpMultiFaqsSection", LazyL4HelpMultiFaqsSection],
["L4HelpTabs", LazyL4HelpTabs],
["L4HelpTile", LazyL4HelpTile],
["L4HelpTilesGrid", LazyL4HelpTilesGrid],
["L4Image", LazyL4Image],
["L4IndexCountries", LazyL4IndexCountries],
["L4LandingSection", LazyL4LandingSection],
["L4Links", LazyL4Links],
["L4Map", LazyL4Map],
["L4MulticolumnLayout", LazyL4MulticolumnLayout],
["L4NewsSection", LazyL4NewsSection],
["L4Page", LazyL4Page],
["L4PageTitle", LazyL4PageTitle],
["L4Partners", LazyL4Partners],
["L4ProductContent", LazyL4ProductContent],
["L4ProductGrid", LazyL4ProductGrid],
["L4ProductHeader", LazyL4ProductHeader],
["L4ProductMap", LazyL4ProductMap],
["L4ProductPage", LazyL4ProductPage],
["L4ProductPricingTable", LazyL4ProductPricingTable],
["L4QuoteForm", LazyL4QuoteForm],
["L4QuoteFormExtended", LazyL4QuoteFormExtended],
["L4QuoteFormHotels", LazyL4QuoteFormHotels],
["L4ReviewsList", LazyL4ReviewsList],
["L4SeoSection", LazyL4SeoSection],
["L4StandardContent", LazyL4StandardContent],
["L4StatsSection", LazyL4StatsSection],
["L4Testimonial", LazyL4Testimonial],
["L4TopLocations", LazyL4TopLocations],
["L4TopRatedCarParks", LazyL4TopRatedCarParks],
["L4TransferOptionsContainer", LazyL4TransferOptionsContainer],
["L4TransfersEmailSignup", LazyL4TransfersEmailSignup],
["L4TrustedPartners", LazyL4TrustedPartners],
["L4TwoColumn", LazyL4TwoColumn],
["LoungeBookingCard", LazyLoungeBookingCard],
["OTCookieButton", LazyOTCookieButton],
["OTCookieTables", LazyOTCookieTables],
["OffersSection", LazyOffersSection],
["OtNotice", LazyOtNotice],
["TabbedSearchboxHero", LazyTabbedSearchboxHero],
["UsefulLinks", LazyUsefulLinks],
["NuxtIcon", LazyNuxtIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
